import React from "react"
import {Link} from "gatsby"

const BubbleLink = ({title, iconLink, links}) => (
  <button className="bubble border-0 d-flex flex-row justify-content-center align-items-start">
    <div className="content">
      <div className="title">{title}</div>
      <div className="bar" />
      <div>
        <div className="links">
          <div>
            { links &&
              links.map((alink, id) => <div key={id} className="link">
                <Link to={alink.link}>
                <div className="text-black">{alink.text}</div>
                </Link>
              </div>)
            }
          </div>
        </div>
        <img src={iconLink} className="icon" alt={title}/>
      </div>
    </div>
  </button>
)

export default BubbleLink