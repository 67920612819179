import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import BubbleLink from "../components/index/bubbleLink"

import engagementIcon from "../images/icon-engagement.png"
import groupeIcon from "../images/picto-groupe.png"
import consultantIcon from "../images/icon-consultant.png"
import bougeIcon from "../images/icon-atom.png"
import collaborationIcon from "../images/icon-collaboration.png"
import SocialButtons from "../components/socialButtons"

const IndexPage = () => (
  <Layout>
    <SocialButtons />
    <SEO title="Accueil" />
    <Hero text="Bienvenue au sein du Groupe CLEEVEN" id="home" />
    <div
      id="home-content"
      className="col-md-12 d-flex flex-row flex-wrap justify-content-center"
    >
      <BubbleLink
        title="Cleeven S'engage"
        iconLink={engagementIcon}
        links={[
          { text: "Politique Qualité", link: "/engagement" },
          { text: "Engagement RSE", link: "/engagement" },
        ]}
      />
      <BubbleLink
        title="Présentation du groupe"
        iconLink={groupeIcon}
        links={[
          { text: "Culture et valeurs", link: "/groupe" },
          { text: "Sociétés et organigramme", link: "/groupe" },
        ]}
      />
      <BubbleLink
        title="Être consultant Cleeven"
        iconLink={consultantIcon}
        links={[
          { text: "Métier de consultant", link: "/consultant" },
          { text: "Suivi de projet", link: "/consultant" },
        ]}
      />
      <BubbleLink
        title="Ça bouge chez Cleeven"
        iconLink={bougeIcon}
        links={[
          { text: "Cleeven Coaching Program", link: "/bouge" },
          { text: "Boost Your Skills", link: "/bouge" },
          { text: "Événements conviviaux", link: "/bouge" },
          { text: "Cooptation", link: "/bouge" },
        ]}
      />
      <BubbleLink
        title="Notre Collaboration"
        iconLink={collaborationIcon}
        links={[
          { text: "Règles de demandes de congès", link: "/collaboration" },
          { text: "Gestion des temps", link: "/collaboration" },
          { text: "Justification des absences", link: "/collaboration" },
        ]}
      />
    </div>
  </Layout>
)

export default IndexPage
